<template>
  <!-- <div class="w-25 shadow"> -->
  <div
    class="p-3 h-100 shadow d-flex justify-content-center align-items-center flex-column"
  >
    <h6>Bitbns Amount</h6>
    <h2 class="font-weight-bolder mb-1">{{ availableAmount }}</h2>
    <!-- chart -->
    <!-- <vue-apex-charts
        height="70"
        :options="statisticsOrder.chartOptions"
        :series="statisticsOrder.series"
      /> -->
  </div>
  <!-- </div> -->
</template>

<script>
// import { BCard } from "bootstrap-vue";
// import VueApexCharts from "vue-apexcharts";

const $barColor = "#f3f3f3";

export default {
  props: ["availableAmount"],
  components: {
    // BCard,
    // VueApexCharts,
  },
  data() {
    return {
      statisticsOrder: {
        series: [
          {
            name: "series-1",
            data: [30, 40, 35, 50, 49, 60, 70, 91],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20%",
              startingShape: "rounded",
              colors: {
                backgroundBarColors: [
                  $barColor,
                  $barColor,
                  $barColor,
                  $barColor,
                  $barColor,
                ],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    };
  },
};
</script>
