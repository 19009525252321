<template>
  <div
    class="p-3 h-100 shadow d-flex justify-content-center align-items-center flex-column"
  >
    <h6>Invested Amount</h6>
    <h2 class="font-weight-bolder mb-1">{{ investedAmount }}</h2>
    <!-- chart -->
    <!-- <vue-apex-charts
        height="70"
        :options="statisticsProfit.chartOptions"
        :series="statisticsProfit.series"
      /> -->
  </div>
</template>

<script>
// import { BCard } from "bootstrap-vue";
// import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    // BCard,
    // VueApexCharts,
  },
  props: ["investedAmount"],
  data() {
    return {
      statisticsProfit: {
        series: [
          {
            name: "series-1",
            data: [30, 40, 35, 50, 49, 60, 70, 91],
          },
        ],
        chartOptions: {
          chart: {
            type: "line",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: "#666",
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          //   colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: "blue",
            strokeColors: "blue",
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: "#ffffff",
                strokeColor: "blue",
                size: 5,
              },
            ],
            shape: "circle",
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: "0px",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    };
  },
};
</script>
