<template>
  <div class="container mt-4 mb-4" v-if="sourceList">
    <div class="row">
      <div class="mb-4 col">
        <available-amount :availableAmount="sourceList.available_amount" />
      </div>
      <div class="mb-4 col">
        <invested-amount :investedAmount="sourceList.invested_amount" />
      </div>
      <div class="mb-4 col">
        <hedge-wallet-amount :hedgeWalletAmount="sourceList.invested_amount" />
      </div>
      <div class="mb-4 col"><pn-l :pnl="sourceList.pnl" /></div>
    </div>

    <!-- <table-component :fields="fields" :data="filteredList" /> -->

    <loader :isLoading="isLoading" />
    <h3 class="mt-4">Source</h3>
    <div class="d-flex flex-wrap mt-4">
      <div
        class="card shadow mr-4 mb-4"
        style="width: 18rem; height: 140px; cursor: pointer"
        v-if="sourceList && sourceList.source && sourceList.source.length == 0"
      >
        <div
          class="card-body text-muted d-flex justify-content-center align-items-center"
        >
          No record found
        </div>
      </div>
      <div
        v-else
        class="card shadow mr-4 mb-4"
        style="width: 18rem; height: 140px; cursor: pointer"
        v-for="(card, i) in sourceList.source"
        :key="i"
        @click="$router.push(`/${card.name.toLowerCase()}`)"
      >
        <div
          class="card-body font-weight-bold d-flex justify-content-center align-items-center"
        >
          {{ card.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { mapState } from "vuex";
import AvailableAmount from "../components/AvailableAmount.vue";
import InvestedAmount from "../components/InvestedAmount.vue";
import PnL from "../components/PnL.vue";
import HedgeWalletAmount from "../components/HedgeWalletAmount.vue";
// import TableComponent from "../components/TableComponent.vue";

export default {
  computed: {
    ...mapState(["sourceList"]),
  },

  components: {
    // BAvatar,
    Loader,
    AvailableAmount,
    InvestedAmount,

    PnL, // BImg,
    HedgeWalletAmount,
    // TableComponent,
  },

  data() {
    return {
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("fetchSourceList");
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
// @import '~@core/scss/base/bootstrap-extended/include';
// @import '~@core/scss/base/components/variables-dark';

// .card-company-table ::v-deep td .b-avatar.badge-light-company {
//   .dark-layout & {
//     background: $theme-dark-body-bg !important;
//   }
// }
.font-small-2 {
  font-size: 13px;
}
div {
  white-space: nowrap;
}
</style>
